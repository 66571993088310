@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.header{
    z-index: 0;
    display: flex;
    top: 0;
    justify-content: center;
    height: 15vh;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.headingFont{
    font-family: 'Open Sans', sans-serif;
    color: rgb(52, 52, 52);
    padding-left: 0.5rem;
}

.logo{
    width: 15vw;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
}

.body{
    z-index: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    height: 85vh;
}

@media screen and (max-width: 1100px){
    .body{
        flex-direction: column;
    }
    .logo{
        width: 10rem;
    }
}


