@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@900&display=swap');


.canvas{
    background-color: transparent;
    height: 85vh;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 85vh;
    background-color: rgba(246, 238, 238, 0.516);
}

.badgeContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 85vh;
    background-color: transparent;
}

.image{
    width: 33rem;
    height: 33rem;
    /* padding-left: 3rem; */
    /* object-fit: cover; */
}

.text{
    display: flex;
    position: relative;
    white-space: pre-line;
}

.textHead{
    font-family: 'Open Sans', sans-serif;
    font-size: 2.5rem;
    color: rgba(52, 52, 52, 0.645);
    padding: 3rem;
    font-weight: 800;
}

.loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(52, 52, 52, 0.645);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overText{
    position: absolute;
    top: 75%;
    left: 50%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    font-weight: 500;
    color: rgba(34, 34, 34, 0.728);
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1100px){
    .canvas{
        width: 100vw;   
        height: 50vh;
    }
    .form{
        width: 100vw;
        height: 100vh;
        padding-top: 5rem;
    }
    .textHead{
        font-size: 2rem;
    }
}

@media (max-width: 768px){
    .image{
        width: 20rem;
        height: 20rem;
    }
    .text{
        font-size: 1.5rem;
    }
    .overText{
        font-size: 1.5rem;
        top: 67%;
    }
    .form{
        width: 100vw;
        padding-top: 2rem;
        padding-left: 0rem;
    }
}

@media (min-height: 750px) and (max-width: 1100px){
    .overText{
        font-size: 1.5rem;
        top: 64%;
    }
}
    

