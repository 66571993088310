@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.container{
    display: flex;
}

.textField{
    width: 25rem;
    height: 2.5rem;
    border-radius: 5px;
    border: 2px solid rgba(114, 114, 235, 0.223);
    font-family: 'Open Sans', sans-serif;
}

.textField:focus{
    /* outline: none; */
    outline: 2px solid rgba(66, 66, 229, 0.506);
    border-radius: 10px;
}

.buttonTray{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1rem;
    margin-left: auto;
}

/* Blue button as seen on translate.google.com*/
.button {
    color: white;
    background: #146eff;
    border: 1px #3079ED solid;
    border-radius: 6px;
    height: 2.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-right: 1rem;
}

.linkedin{
    color: white;
    background-color: #0a429c;
    border: 1px #244985 solid;
    border-radius: 6px;
    height: 2.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-right: 1rem;
}

.button:hover {
    border: 1px #2F5BB7 solid;
    box-shadow: 0 1px 1px #c2b7b73b, inset 0 0.2px 0 #5a94f100;
    background: #1566e9;
}

.button:active {
    box-shadow: inset 0 2px 5px #2370FE;   
}

.cvs{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

@media (max-width: 1100px) {
    .textField{
        width: 20rem !important;
    }
    .buttonTray{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .button{
        width: 10rem;
    }
    .linkedin{
        margin-top: 1rem;
    }
}